import { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import '../css/navbar.css'
import logo from '../img/logo.png'
import menuImg from '../img/menu.png'
import arrow from '../img/arrowDown.png'

const initialState = false


const Navbar = () => {


    const [opacity, setOpacity] = useState(false)

    const [wrapp, setWrapp] = useState(false)

    const [menu, setMenu] = useState(false)


const changeOpacity = () => {
    if (window.scrollY >= 81  && window.innerWidth > 992) {
        setOpacity(true)
    } else {
        setOpacity(false)
    }
}


useEffect(() => {
  changeOpacity()

  window.addEventListener('scroll',changeOpacity)
})

const changeWrapp = () =>{
    
    setWrapp(!wrapp)
}

const changeMenu = () =>{
     if (window.innerWidth <= 992) {
         setMenu(!menu)
     }else if(window.innerWidth > 992 && wrapp === false){
        setWrapp(wrapp)
     }else{
        setWrapp(!wrapp)
     }
   
}
/*
useEffect(() => {
    if ((!menu && !wrapp || menu && !wrapp)){
        setWrapp(wrapp)
}  else  {
    setWrapp(!wrapp)
}

}, [menu])*/

useEffect(() => {
    if ((!menu && !wrapp || menu && !wrapp)){
        setWrapp(wrapp)
}  else  {
    setWrapp(!wrapp)
}

}, [menu])

useEffect(()=>{
    if( window.innerWidth > 992)
        setMenu(!menu)
},[])





 
  return (
    
    <nav className={opacity ? 'active' : 'desactive'}>
        <img className='logo' src={logo} alt='lbdesing'/>
        <div className='menuWrap' onClick={() => changeMenu()}>
            <div className={menu ? 'linea1': 'linea1D'}></div>
            <div className={menu ? 'linea2': 'linea2D'}></div>
            <div className={menu ? 'linea3': 'linea3D'}></div>
        </div>
            <div className={menu ? 'menuActive': 'menuDesactive'}>
                <ul className='menu'   /*      <img className='menuImg' onClick={() => changeMenu()}  src={menuImg}/>*/>
                <li className='itemLista1' style={{display: 'flex', flexDirection:'row', alignItems:'end'}}  onClick={() => changeWrapp()}>servicios<span>▼</span></li>
                <ul className={wrapp && menu ? 'wrappActive' : 'wrappDesactive'}>
                    <li className='itemLista' ><Link to='sitioWeb' onClick={() => changeMenu()} smooth={true} offset={-75} duration={500}>sitio web</Link> </li>
                    <li className='itemLista' ><Link to='ecommerce'  onClick={() => changeMenu()}  smooth={true} offset={-75} duration={500}>ecommerce</Link></li>
                    <li className='itemLista' ><Link to='landingPage'  onClick={() => changeMenu()}  smooth={true} offset={-75} duration={500}>landing page</Link></li>
                </ul>
                <li className='itemLista'><Link  to='form' onClick={() => changeMenu()}  smooth={true} offset={-75} duration={500}>contacto</Link></li>
                <a className='itemLista' href='https://wa.me/5491126134318' target='blank'><li>whatsapp</li></a>
                
                
            </ul>
            </div>
        
    </nav>
  )
}

export default Navbar