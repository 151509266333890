import {useState, useRef } from "react";

import axios from 'axios';

import fondo from '../img/img5.png'
import whatsapp from '../img/whatsapp.png'
import mail from '../img/correo.png'
import '../css/form.css'



const initalForm = {
    name:"",
    email:"",
    message:""
}

const Form = () => {

    const inputRefName = useRef(null);
    const inputRefEmail = useRef(null);
    const messageRef = useRef(null)

    const [form, setForm] = useState(initalForm)


    const message = async(data) =>{
        const ENDPOINT = "https://lbdes.vercel.app/email"
        
       /* const ENDPOINT = "https://hhh-iota-eosin.vercel.app/email"*/
        /* const ENDPOINT = "http://localhost:3000/email"*/

        await axios.post(ENDPOINT, data)
        console.log(data)
    }

    const handleChange = (e) =>{
        setForm({
            ...form,
            [e.target.name] : e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!form.name || !form.email) {
           
                inputRefName.current.placeholder= 'El nombre es obligatorio'
                inputRefName.current.classList.add('newPlaceholder')
                inputRefEmail.current.placeholder= 'El email es obligatorio'
                inputRefEmail.current.classList.add('newPlaceholder')
            return;
        }else if(!form.email){
            inputRefEmail.current.placeholder= ''
            inputRefEmail.current.classList.add('newPlaceholder')
        }
         else {
            inputRefName.current.placeholder= 'nombre*'
            inputRefName.current.classList.remove('newPlaceholder')
            inputRefEmail.current.placeholder= 'Email*'
            inputRefEmail.current.classList.remove('newPlaceholder')
            message(form)
            setForm(initalForm)
              messageRef.current.classList.add('messageSend')
            const timer = setTimeout(()=>{
                messageRef.current.classList.remove('messageSend')
            },2000);

            return()=>clearTimeout(timer)
           // window.location.reload(true)
        }
    }

  return (
    <div className='divContainer' id="form">
        <img src={fondo} alt="paginaWeb" className='fondoForm' />
      
       

        <form onSubmit={handleSubmit} className="formContainer">
            <h3>ENVIANOS TU MENSAJE</h3>
            <input className="inputForm" type='text' name='name' placeholder='nombre*' ref={inputRefName} onChange={handleChange} value={form.name} />
            <input className="inputForm"  type='email'  name='email' placeholder='email*' ref={inputRefEmail} onChange={handleChange} value={form.email} />
            <textarea name='message' placeholder='mensaje 'onChange={handleChange} value={form.message}/>
            <input type='submit' value='Enviar' className="send"/>
            <div className="messageNSubmit" ref={messageRef}>
                <h4>mensaje enviado</h4>
            </div>
        
        </form>

    </div>
  )
}

export default Form