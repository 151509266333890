import { useState, useEffect } from 'react'
import arrow from '../img/flechaArriba.png'
import { Link } from 'react-scroll'

import React from 'react'
import'../css/buttom.css'

const UpButton = () => {

    const [see, setSee] = useState(false)

    const changeSee = () => {
        if (window.scrollY >= 200 ) {
            setSee(true)
        } else {
            setSee(false)
        }
    }

    useEffect(() => {
        changeSee()
      
        window.addEventListener('scroll',changeSee)
      })

  return (
    <Link to='app' smooth={true} offset={-75} duration={500}><img src={arrow} className={see ? 'buttonActive' : 'buttonDesactive'} /></Link>
  )
}

export default UpButton