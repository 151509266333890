import fondo from '../img/fond.png'
import img from '../img/SitioWeb.png'
import '../css/secciones.css'

const SitioWeb = () => {
  return (
    <section className='seccionContainer' id='sitioWeb'>
        <img src={fondo} className='fondo'/>
        <div className='cardAnimation'>
            <h2 className='title'>Sitio Web</h2>
        </div>
        <div className='dataContainer'>
            <img src={img} alt='sitio web' style={{zIndex:50}}/>
            <div className='parrafoContainer'>
                <p className='parrafo'>
                    Permiten presentar tu empresa, tus productos y/o servicios. ¡Atrae la atención de tus clientes con un diseño único!
                    El objetivo principal es mantener una marcada presencia en Internet y poder ser encontrado por los buscadores. 
                    Tus potenciales clientes podrán conocerte, explorar tus productos/servicios y contactarte.
                </p>
                <a className='info'  href='https://wa.me/5491126134318?text=Hola me gustaria consultar por un Sitio Web.' target='blank' ><button>Mas informacion</button></a>
            </div>
        </div>
    </section>
  )
}

export default SitioWeb