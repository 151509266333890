import whatsapp from '../img/whatsapp.png'
import '../css/whatsapp.css'


const WhatsappButton = () => {
  return (
    <a href='https://wa.me/5491126134318 ' target='blank'><img src={whatsapp} className='whatsapp'/></a>
  )
}

export default WhatsappButton