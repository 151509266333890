import fondo from '../img/fond.png';
import img from '../img/ecommerce.png';
import '../css/secciones.css'




const Ecommerce = () => {
  return (
    <section className='seccionContainer' id='ecommerce'>
    <img src={fondo} className='fondo'/>
    <div className='cardAnimation'>
        <h2 className='title'>Ecommerce</h2>
    </div>
    <div className='dataContainer'>
        <img src={img} alt='ecommerce'/>
        <div className='parrafoContainer'>
            <p className='parrafo'>
            Permiten presentar tu empresa, tus productos y/o servicios. ¡Atrae la atención de tus clientes con un diseño único!
            ¡Dale vida a tu negocio en línea con nuestro servicio de creación de eCommerce! Con una combinación perfecta de diseño intuitivo, 
            funcionalidad avanzada y una plataforma fácil de usar, te proporcionamos la solución completa para lanzar y hacer crecer tu tienda virtual.
            </p>
            <a  className='info'  href='https://wa.me/5491126134318?text=Hola, me gustaria consultar por un Ecommerce.' target='blank' ><button>Mas informacion</button></a>
        </div>
    </div>
</section>
  )
}

export default Ecommerce