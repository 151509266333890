
import './App.css';
import Navbar from './components/Navbar';
import Slider from './components/Slider';
import SitioWeb from './components/SitioWeb';
import LandingPage from './components/LandingPage';
import Ecommerce from './components/Ecommerce';
import Form from './components/Form';
import Footer from './components/Footer';
import WhatsappButton from './components/WhatsappButton';
import UpButton from './components/UpButton';

function App() {
  return (
    <div className='App' id='app' style={{ width:'100%',display:'flex', flexDirection:'column', backgroundColor:'rgb(230,230,255)'}}>
      <header>
      <Navbar/>
      </header>
      <main>
        <Slider/>
        <SitioWeb/>
        <LandingPage/>
        <Ecommerce/>
        <Form/>
        <WhatsappButton/>
        <UpButton/>
      </main>
      <footer>
        <Footer/>

      </footer>
    </div>
  );
}

export default App;
