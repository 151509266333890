import logo from '../img/logo.png'
import '../css/footer.css'

const Footer = () => {
  return (
    <div className='footerContainer'>
      <img className='logoFooter' src={logo} alt='lbdesing'/>
      <h4>© 2024 Lbdesing.com</h4>
    </div>
  )
}

export default Footer