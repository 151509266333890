import fondo from '../img/fond.png';
import img from '../img/LandingPage.png';
import '../css/secciones.css'



const LandingPage = () => {
  return (
    <section className='seccionContainer' id='landingPage'>
        <img src={fondo} className='fondo'/>
        <div className='cardAnimation'>
            <h2 className='title'>Landing Page</h2>
        </div>
        <div className='dataContainer'>
            <img src={img} alt='landing page'/>
            <div className='parrafoContainer'>
                <p className='parrafo'>
                Permiten presentar tu empresa, tus productos y/o servicios. ¡Atrae la atención de tus clientes con un diseño único!
                ¿Buscas captar la atención de tu audiencia y convertir visitantes en clientes? ¡No busques más! 
                Con nuestro servicio de creación de Landing Pages, podemos ayudarte a destacar en línea y alcanzar tus objetivos comerciales.
                Desde un diseño atractivo y responsive hasta contenido persuasivo y llamadas a la acción efectivas.
                </p>
                <a className='info' href='https://wa.me/5491126134318?text=Hola, me gustaria consultar por una Landing Page.' target='blank' ><button>Mas informacion</button></a>
            </div>
        </div>
    </section>
  )
}

export default LandingPage