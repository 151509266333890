import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination, Navigation, EffectFade} from 'swiper/modules';

import { useState, useEffect, useRef } from 'react';
import 'swiper/css'
import '../css/slider.css'
import "swiper/css/effect-fade";
import 'swiper/swiper-bundle.css';



import fondo from '../img/fondo1.png'



const Slider = () => {

  const texts = [<h1 style={{display:'flex', textAlign:'center'}}>Tu marca tu historia nuestra página web.</h1>, 
  <h1 style={{display:'flex', textAlign:'center'}}>Diseñamos tu presencia digital página a página.</h1>, 
  <h1 style={{display:'flex', textAlign:'center'}}>Transformamos tu visión en una experiencia web única.</h1>
];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>

          <img img src={fondo} style={{position:'relative', width: '100%', background: 'cover', height:'100vh', zIndex:'10'}} alt=""/>
        <Swiper
        style={{
            height:'100vh',
            position:'absolute',
            width:'100%',
            zIndex:'20', justifyContent:'center', alignItems:'center'
           }}
           //slidesPerView={1}
           //spaceBetween={30}
             effect={'fade'}
           //croosFade = {true}
          // navigation ={true}
           loop={true}
           autoplay = {{delay:2000}}
           speed={3000}
           onSlideChange={handleSlideChange}

           modules={[Autoplay, Pagination, EffectFade]}
        >
          
             {
              texts.map((text, index) => (
                <SwiperSlide key={index} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute',width:'80%', display: index === currentIndex ? 'block' : 'none' }}>
                  {text}
                </div>
                </SwiperSlide>
              ))
            } 
              
              
              
            
        </Swiper>
    </div>
  )
}

export default Slider